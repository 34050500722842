import { toast } from 'react-toastify';
import BASE_URL from '@common/config/endpoint';
import { PATH_URL_PC } from '@common/config/pathURL';
import cache, { sessionCache } from '@common/utils/cache';
import axios from 'axios';

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const token = cache.get('token') || sessionCache.get('token');
    // eslint-disable-next-line no-param-reassign
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.request.responseType === 'blob') {
      return response;
    }
    return response.data;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response.data.message === 'Unauthorized' &&
      window.location.pathname !== PATH_URL_PC.LOGIN &&
      !error.config._retry
    ) {
      error.config._retry = true;
      try {
        const refreshToken =
          cache.get('refreshToken') || sessionCache.get('refreshToken');

        const res = await api.post('/refreshToken', {
          refresh_token: refreshToken,
        });

        if (cache.get('token')) {
          cache.set('token', res?.access_token);
          cache.set('refreshToken', res?.refresh_token);
        } else {
          sessionCache.set('token', res?.access_token);
          sessionCache.set('refreshToken', res?.refresh_token);
        }
        error.config.headers.Authorization = `Bearer ${res?.access_token}`;
        return api(error.config);
      } catch (err) {
        if (cache.get('token')) {
          cache.remove('token');
          cache.remove('refreshToken');
        } else {
          sessionCache.remove('token');
          sessionCache.remove('refreshToken');
        }
        window.location = PATH_URL_PC.LOGIN;
      }
    } else {
      const notShowToastEnpoints = [
        'login',
        'base/upload',
        'trips/add_service_lot',
        'trips/reassign',
        'm_users',
        'order/upload',
        'bc_orders',
      ];
      let isShowToast = true;
      notShowToastEnpoints.every((e) => {
        if (
          error.request.responseURL.includes(e) &&
          error.response.config.method === 'post'
        ) {
          isShowToast = false;
          return false;
        }
        return true;
      });

      if (isShowToast) {
        toast('予期せぬエラーが発生しました。', {
          type: 'error',
        });
      }
    }
    return Promise.reject(error);
  },
);

export default api;
