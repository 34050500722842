import React from 'react';
import { Button, Modal, Row } from 'antd';
import PropTypes from 'prop-types';

function MyModal({
  title,
  handleOk,
  handleCancel,
  isShowModal,
  confirmMod,
  okText,
  cancelText,
  width,
  setIsShowModal,
  disabled,
}) {
  const setCloseModal = () => {
    setIsShowModal(false);
  };
  const closeModal = setIsShowModal ? setCloseModal : handleCancel;

  return (
    <Modal
      className="p-50"
      open={isShowModal}
      onCancel={closeModal}
      footer={false}
      width={width}
    >
      <div>
        <h1 className="text-center my-50 fz-24 fw-700">{title}</h1>
        <Row justify="center" className="mb-50">
          <Button
            onClick={handleOk}
            danger={!confirmMod}
            className="mr-20 w-120"
            type="primary"
            disabled={disabled}
          >
            {okText || 'OK'}
          </Button>
          <Button onClick={handleCancel} className="w-120">
            {cancelText || 'キャンセル'}
          </Button>
        </Row>
      </div>
    </Modal>
  );
}

export default MyModal;

MyModal.propTypes = {
  title: PropTypes.string,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  isShowModal: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  confirmMod: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  width: PropTypes.string,
  setIsShowModal: PropTypes.func,
  disabled: PropTypes.bool,
};
