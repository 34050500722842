import React, { useEffect } from 'react';
import { useAlert } from '@common/contexts/Alert';
import { Alert } from 'antd';

function MyAlert() {
  const { alert, setAlert } = useAlert();

  const closeAlert = () => {
    setAlert({ msg: '', type: null });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert({ msg: '', type: null });
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [alert.msg]);

  return (
    alert.msg && (
      <Alert
        message={alert.msg}
        type={alert.type}
        showIcon
        closable
        style={{ width: 400 }}
        onClose={closeAlert}
      />
    )
  );
}

export default MyAlert;
