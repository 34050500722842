import api from './baseApi';

const attenDanceApi = {
  getAttenDanceSumary(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/attendances/summary?${childPath}`);
  },
  postAttenDance(data) {
    return api.post('attendances', data);
  },
  deleteAttenDance(id) {
    return api.delete(`attendances/${id}`);
  },
  getListAttenDance(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/attendances?${childPath}`);
  },
  updateAttenDance(data, id) {
    return api.put(`/attendances/${id}`, data);
  },
};
export default attenDanceApi;
