const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const objectToFormdata = (values, fieldDelete) => {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    switch (key) {
      case 'attendance_information':
      case 'fares': {
        if (value && value?.length) {
          formData.append(key, JSON.stringify(value));
        }
        break;
      }
      case 'files': {
        if (value && value?.length) {
          const length = value?.length;
          const listFile = [];
          for (let x = 0; x < length; x += 1) {
            if (value[x].originFileObj) {
              formData.append('files', value[x].originFileObj);
            } else if (value[x].deleteFlg) {
              delete value[x].uid;
              listFile.push(value[x]);
            }
          }
          if (listFile?.length) {
            formData.append(fieldDelete, JSON.stringify(listFile));
          }
        }
        break;
      }
      case 'file': {
        if (value && value.lastModified) {
          formData.append('file', value);
        } else if (value === 1) {
          formData.append('avatarDeleteFlg', 1);
        }
        break;
      }
      default:
        if (!value) {
          formData.append(key, '');
        } else {
          formData.append(key, String(value)?.trim());
        }
        break;
    }
  });
  return formData;
};

export default getBase64;
