import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_URL_PC } from '@common/config/pathURL';
import { useLayout } from '@common/contexts/Layout';
import cache, { sessionCache } from '@common/utils/cache';
import { useGetProfile } from '@services/hooks/auth';
import { useGetDivs } from '@services/hooks/masterData';
import { Layout, Spin } from 'antd';
import PropTypes from 'prop-types';
import Header from './Header';
import Sider from './Sider';

const { Content } = Layout;

function LayoutWraper({ children }) {
  const { data: profile } = useGetProfile();
  const { collapsed, isLoading } = useLayout();
  useGetDivs(Boolean(profile));

  const navigate = useNavigate();

  useEffect(() => {
    const token = cache.get('token') || sessionCache.get('token');
    if (!token) {
      navigate(PATH_URL_PC.LOGIN);
    }
  }, []);

  return (
    <Layout
      id="layout-wraper"
      className={`${collapsed ? 'collapsed' : 'uncollapsed'}`}
    >
      <Sider profile={profile} />
      <Layout>
        <Header profile={profile} />
        <Spin spinning={isLoading} size="large" wrapperClassName="spin-loading">
          <Content className="layout-wraper-content pos-rel">
            {children}
          </Content>
        </Spin>
      </Layout>
    </Layout>
  );
}

export default LayoutWraper;

LayoutWraper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
