import { WEEK_DAY_DIV } from '@common/constant/masterData';
import dayjs from 'dayjs';
import { arrayDiff } from './arrayHelper';
import {
  dateDisplay,
  dayjsToTime,
  formatDateOrder,
  formatTimeOrder,
  hourDisplay,
} from './dateHelper';

const getSortString = (type, order, withoutDate) => {
  if (withoutDate === 'cargos') {
    return order === 'descend' ? 'cargoNm DESC' : 'cargoNm ASC';
  }
  return order === 'descend'
    ? `order.${type}Ymd DESC,order.${type}TimeFrom DESC,order.${type}TimeTo DESC`
    : `order.${type}Ymd ASC,order.${type}TimeFrom ASC,order.${type}TimeTo ASC`;
};

export const generateDowFlags = (dowFlg, dowDiv) => {
  const dowFlgPost = {};
  WEEK_DAY_DIV.every((dow) => {
    dowFlgPost[dow.value] = '1';
    return true;
  });

  if (!['01', '02', '03'].includes(dowDiv)) {
    WEEK_DAY_DIV.every((dow) => {
      if (!dowFlg?.includes(dow.value)) {
        dowFlgPost[dow.value] = '0';
      }
      return true;
    });
  }
  return dowFlgPost;
};

export const getCargosToPacth = (oldCargos, newCargos, type) => {
  const propCompare = type === 'regular' ? 'regularCargoId' : 'cargoId';

  const onlyInOldCargos = (_oldCargos, _newCargos) =>
    _oldCargos?.filter((leftValue) => {
      const notInRight = !_newCargos?.some(
        (rightValue) => leftValue[propCompare] === rightValue[propCompare],
      );
      return notInRight;
    });

  const removedCargos = onlyInOldCargos(oldCargos, newCargos);
  const removedCargosWithFlag = removedCargos?.map((cargo) => ({
    ...cargo,
    deleteFlg: '1',
  }));
  const cargosToPatch = newCargos?.concat(removedCargosWithFlag);
  return cargosToPatch;
};

export const getInitOrderData = (orderData) => {
  const initialDataOrder = {
    ...orderData,
    pickupYmd: dayjs(orderData?.pickupYmd),
    deliveryYmd: dayjs(orderData?.deliveryYmd),
    pickupTime: [
      formatTimeOrder(orderData?.pickupTimeFrom, 'get'),
      formatTimeOrder(orderData?.pickupTimeTo, 'get'),
    ],
    collectMoneyAmount:
      orderData?.collectMoneyAmount && orderData?.collectMoneyAmount !== '0'
        ? orderData?.collectMoneyAmount
        : null,
    deliveryTime: [
      formatTimeOrder(orderData?.deliveryTimeFrom, 'get'),
      formatTimeOrder(orderData?.deliveryTimeTo, 'get'),
    ],
    files: orderData?.tOrderImages?.map((file) => ({
      uid: file.imageId,
      name: file.imageId,
      status: 'done',
      url: file.url,
    })),
  };

  return initialDataOrder;
};

export const getInitRegularData = (regularData) => {
  const dowFlg = [];

  if (regularData) {
    WEEK_DAY_DIV.every((dow) => {
      if (regularData[dow.value] === '1') {
        dowFlg.push(dow.value);
      }
      return true;
    });
  }

  const dataOrderInit = regularData && {
    ...regularData,
    dowFlg,
    pickupTime: [
      formatTimeOrder(regularData?.pickupTimeFrom, 'get'),
      formatTimeOrder(regularData?.pickupTimeTo, 'get'),
    ],
    deliveryTime: [
      formatTimeOrder(regularData?.deliveryTimeFrom, 'get'),
      formatTimeOrder(regularData?.deliveryTimeTo, 'get'),
    ],
    fromTerm: formatDateOrder(regularData?.fromTerm, 'get'),
    toTerm: formatDateOrder(regularData?.toTerm, 'get'),
  };

  return dataOrderInit;
};

export const handleFilterDropdown = (input, option) => {
  return option.children.includes(input) || option.knName?.includes(input);
};

export const changeValuebyLabel = (arr) => {
  return arr?.map((item) => ({
    label: item.label,
    value: item.label,
  }));
};
export const switchSortOrder = (sortItem) => {
  const { field, order } = sortItem;
  switch (field) {
    case 'orderId':
    case 'orderWayDiv':
    case 'statusDiv':
    case 'pickupNm':
    case 'deliveryName':
      return [
        order === 'descend' ? `order.${field} DESC` : `order.${field} ASC`,
      ];
    case 'customerNm':
      return [
        order === 'descend'
          ? `customer.${field} DESC`
          : `customer.${field} ASC`,
      ];

    case 'pickupYmd':
      return [getSortString('pickup', order)];
    case 'deliveryYmd':
      return [getSortString('delivery', order)];
    case 'regularNm':
      return [
        order === 'descend' ? `regular.${field} DESC` : `regular.${field} ASC`,
      ];
    case 'cargos':
      return [getSortString('delivery', order, 'cargos')];
    default:
      return [order === 'descend' ? `${field} DESC` : `${field} ASC`];
  }
};

export const genOrderFormData = (values) => {
  const formData = new FormData();
  Object.entries(values).forEach(([key, value]) => {
    switch (key) {
      case 'cargos':
        formData.append('cargos', JSON.stringify(value));
        break;
      case 'files': {
        if (value) {
          const length = value?.length;
          for (let x = 0; x < length; x += 1) {
            formData.append('files', value[x].originFileObj);
          }
        }
        break;
      }
      default:
        if (value != null) {
          formData.append(key, String(value)?.trim());
        }
        break;
    }
  });
  return formData;
};

export const genPatchOrderFormData = (dataOrder, initialDataOrder) => {
  const formData = new FormData();
  const oldCargos = initialDataOrder.cargos;
  const newCargos = dataOrder.cargos;
  const cargosToPatch = getCargosToPacth(oldCargos, newCargos);

  const listFileNameInit = initialDataOrder?.files?.map((e) => String(e.uid));
  const listFile = dataOrder?.files;
  const oldFiles = [];
  const newFiles = [];

  listFile?.forEach((file) => {
    if (file?.type) newFiles.push(file);
    else oldFiles.push(String(file?.uid));
  });

  const deletedFiles = arrayDiff(oldFiles, listFileNameInit);

  const dataToPatch = {
    ...dataOrder,
    cargos: cargosToPatch,
    files: newFiles,
    deletedFiles: deletedFiles.map((imageId) => ({
      imageId: Number(imageId),
      deleteFlg: '1',
    })),
  };

  Object.entries(dataToPatch).forEach(([key, value]) => {
    switch (key) {
      case 'cargos':
        if (value) {
          formData.append('cargos', JSON.stringify(value));
        }
        break;
      case 'files': {
        if (value) {
          const length = value?.length;
          for (let x = 0; x < length; x += 1) {
            formData.append('files', value[x].originFileObj);
          }
        }
        break;
      }
      case 'deletedFiles': {
        if (value) {
          formData.append('listFile', JSON.stringify(value));
        }
        break;
      }
      default:
        if (value) {
          formData.append(key, String(value)?.trim());
        }
        break;
    }
  });
  if (!formData.has('deliveryBaseId')) {
    formData.append('deliveryBaseId', '0');
  }
  if (!formData.has('deliveryPointId')) {
    formData.append('deliveryPointId', '0');
  }
  return formData;
};

export const renRowsWithOrdId = (data, selectRows) =>
  selectRows.map((row) => ({
    ...row,
    orderId: data.orderId,
    cargoNm: data.cargos?.at(0)?.cargoNm,
    pickupYmd: dateDisplay(data.pickupYmd),
    pickupTime:
      data.pickupTimeDiv === '01'
        ? '希望なし'
        : data.pickupTimeFrom &&
          data.pickupTimeTo &&
          `${hourDisplay(data.pickupTimeFrom)}-${hourDisplay(
            data.pickupTimeTo,
          )}`,
    deliveryTime:
      data.deliveryTimeDiv === '01'
        ? '希望なし'
        : data.deliveryTimeFrom &&
          data.deliveryTimeTo &&
          `${hourDisplay(data.deliveryTimeFrom)}-${hourDisplay(
            data.deliveryTimeTo,
          )}`,
  }));

export const getRangeTimeToCompare = (timeDiv, timeArr) => {
  let time = [];
  switch (timeDiv) {
    case '02':
      time = [dayjsToTime(timeArr?.at(0)), dayjsToTime(timeArr?.at(1))];
      break;
    case '03':
      time = [9, 12];
      break;
    case '04':
      time = [12, 14];
      break;
    case '05':
      time = [14, 16];
      break;
    case '06':
      time = [16, 18];
      break;
    default:
      break;
  }
  return time;
};
