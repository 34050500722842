export const MODE = {
  MOVE_OUT: 'move_out',
  REPAIR: 'repair',
};
export const ROLES = {
  SYSTEM_ADMIN: '01',
  ADMIN: '02',
  STAFF: '03',
  DRIVER: '04',
};

export const AUTH_ROLES = {
  SYSTEM_ADMIN: '01',
  ADMIN: '02',
  CUSTOMER_ADMIN: '03',
  CUSTOMER_COMPANY_ADMIN: '04',
  COMPANY_ADMIN: '05',
  STAFF: '06',
  DRIVER: '07',
};

export const ADMINS = [AUTH_ROLES.SYSTEM_ADMIN, AUTH_ROLES.ADMIN]; // 01, 02
export const LOCAL_ADMINS = [
  AUTH_ROLES.SYSTEM_ADMIN,
  AUTH_ROLES.ADMIN,
  AUTH_ROLES.CUSTOMER_COMPANY_ADMIN,
  AUTH_ROLES.COMPANY_ADMIN,
]; // 01, 02, 04, 05
export const ALL_ADMINS = [
  AUTH_ROLES.SYSTEM_ADMIN,
  AUTH_ROLES.ADMIN,
  AUTH_ROLES.CUSTOMER_ADMIN,
  AUTH_ROLES.CUSTOMER_COMPANY_ADMIN,
  AUTH_ROLES.COMPANY_ADMIN,
]; // 01, 02, 03, 04, 05

export const BARCODE_AUTH = [
  AUTH_ROLES.SYSTEM_ADMIN,
  AUTH_ROLES.ADMIN,
  AUTH_ROLES.CUSTOMER_COMPANY_ADMIN,
  AUTH_ROLES.COMPANY_ADMIN,
  AUTH_ROLES.DRIVER,
];
