import { useMutation, useQuery } from 'react-query';
import attenDanceApi from '@services/apis/attenDance';

export const useGetAttenDanceSummary = (params) => {
  return useQuery(
    ['get-attenDance-summary', params],
    () => attenDanceApi.getAttenDanceSumary(params),
    {
      keepPreviousData: true,
      staleTime: 5000,
    },
  );
};
export const useGetListAttenDance = (params) => {
  return useQuery(
    ['get-list-attenDance', params],
    () => attenDanceApi.getListAttenDance(params),
    {
      keepPreviousData: true,
      staleTime: 0,
    },
  );
};
export const usePostAttenDance = () => {
  return useMutation((params) => attenDanceApi.postAttenDance(params));
};
export const useUpdateAttenDance = () =>
  useMutation(({ params, id }) => attenDanceApi.updateAttenDance(params, id));
