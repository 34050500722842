import api from './baseApi';

const authApi = {
  login(params) {
    return api.post('/login', params);
  },
  resetPwd(params) {
    return api.post('/passwordReset', params);
  },
  changePwd(params) {
    return api.post('/passwordChange', params);
  },
  getMe() {
    return api.get('/profile');
  },
  initPwd(params) {
    return api.post('/passwordInit', params);
  },
};

export default authApi;
