import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_URL_PC } from '@common/config/pathURL';
import cache, { sessionCache } from '@common/utils/cache';
import { usePostLogin } from '@services/hooks/auth';
import { Button, Checkbox, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PropTypes from 'prop-types';

function FormLogin({ handleDisplayResetPwdForm }) {
  const { error, mutate } = usePostLogin();
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const [form] = useForm();

  const msgError = '認証情報が正しくありません。もう一度試してください。';

  const handleSubmitForm = (params) => {
    const { userId, password, remember } = params;
    const trimId = userId.trim();
    mutate(
      { userId: trimId, password },
      {
        onSuccess: (res) => {
          if (remember) {
            cache.set('token', res.access_token);
            cache.set('refreshToken', res.refresh_token);
          } else {
            sessionCache.set('token', res.access_token);
            sessionCache.set('refreshToken', res.refresh_token);
          }

          navigate(PATH_URL_PC.HOME);
        },
      },
    );
  };

  const formChange = () => {
    setIsError(false);
  };

  useEffect(() => {
    if (error) setIsError(true);
  }, [error]);

  useEffect(() => {
    const token = cache.get('token') || sessionCache.get('token');
    if (token) {
      navigate(PATH_URL_PC.HOME);
    }
  }, []);

  return (
    <Form
      className="form-login"
      initialValues={{ remember: true }}
      form={form}
      onFinish={handleSubmitForm}
      onValuesChange={formChange}
    >
      {isError && (
        <Typography.Title className="text-start" type="danger" level={5}>
          {msgError}
        </Typography.Title>
      )}
      <div className="mb-5 text-start fz-14">ログインID</div>
      <Form.Item
        className="mb-25"
        rules={[{ required: true, message: 'ログインIDは必須入力です。' }]}
        name="userId"
      >
        <Input placeholder="IDを入力してください" status={isError && 'error'} />
      </Form.Item>
      <div className="mb-5 text-start fz-14">パスワード</div>
      <Form.Item
        name="password"
        className="mb-25"
        rules={[{ required: true, message: 'パスワードは必須入力です。' }]}
      >
        <Input.Password
          iconRender={(visible) =>
            visible ? 'パスワードを非表示' : 'パスワードを表示'
          }
          status={isError && 'error'}
        />
      </Form.Item>
      <Button htmlType="submit" type="primary" className="w-full mb-15">
        ログイン
      </Button>
      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>ログイン情報を保存する</Checkbox>
      </Form.Item>
      <Button
        size="small"
        className="primary-color"
        type="link"
        onClick={handleDisplayResetPwdForm}
      >
        パスワードをお忘れの場合
      </Button>
    </Form>
  );
}

export default FormLogin;
FormLogin.propTypes = {
  handleDisplayResetPwdForm: PropTypes.func,
};
