import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import routes from '@common/config/routers';
import { Breadcrumb as BreadcrumbAntd } from 'antd';

function Breadcrumb() {
  const location = useLocation();

  const breadcrumbNameMap = {};
  routes.forEach((route) => {
    if (route.breadcrumb) breadcrumbNameMap[route.path] = route.breadcrumb;
  }, {});

  const pathSnippets = location.pathname.split('/').filter((i) => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    let breadcrumbMatch;
    // case route with param
    if (!breadcrumbNameMap[url]) {
      routes.some(({ breadcrumb, path }) => {
        const match = matchPath({ exact: true, path }, url);
        if (match) {
          breadcrumbMatch = breadcrumb;
          return true;
        }

        return false;
      });
    }
    return (
      <BreadcrumbAntd.Item key={url}>
        <Link to={url}>{breadcrumbNameMap[url] || breadcrumbMatch}</Link>
      </BreadcrumbAntd.Item>
    );
  });

  const breadcrumbItems = [
    <BreadcrumbAntd.Item key="home">
      <Link to="/">ホーム</Link>
    </BreadcrumbAntd.Item>,
  ].concat(extraBreadcrumbItems);

  return <BreadcrumbAntd>{breadcrumbItems}</BreadcrumbAntd>;
}

export default Breadcrumb;
