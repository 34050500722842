import api from './baseApi';

const companiesAPI = {
  getListCompanies(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/companies?${childPath}`);
  },
  getCompany(id) {
    return api.get(`/companies/${id}`);
  },
  postCompany(params) {
    return api.post('/companies', params);
  },
  updateCompany(params, id) {
    return api.put(`./companies/${id}`, params);
  },
  deleteCompany(id) {
    return api.delete(`./companies/${id}`);
  },
};

export default companiesAPI;
