import { useMutation, useQuery } from 'react-query';
import companiesAPI from '@services/apis/companies';

export const useGetListCompanies = (params) =>
  useQuery(
    ['get-companies', params],
    () => companiesAPI.getListCompanies(params),
    {
      staleTime: Infinity,
      keepPreviousData: true,
    },
  );
export const useGetCompany = (id) =>
  useQuery(['get-company-detail', id], () => companiesAPI.getCompany(id), {
    enabled: !!id,
    staleTime: Infinity,
  });

export const usePostCompany = () => {
  return useMutation((params) => companiesAPI.postCompany(params));
};

export const useUpdateCompany = () => {
  return useMutation(({ params, id }) =>
    companiesAPI.updateCompany(params, id),
  );
};

export const useDeleteCompany = () => {
  return useMutation(({ id }) => companiesAPI.deleteCompany(id));
};
