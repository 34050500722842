import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import getBase64 from '@common/utils/uploadHelper';
import { Form, Modal, Upload } from 'antd';
import PropTypes from 'prop-types';

function FormItemUpload({ name, isEdit, ...props }) {
  const { flagRemove, noShowTitle } = { ...props };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [maxCount, setMaxCount] = useState();
  const [fileList] = useState([]);
  const form = Form.useFormInstance();
  const fileListForm = Form.useWatch(name, form);
  const lenghtFileList = fileListForm?.length;

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    );
  };

  const beforeUpload = (file) => {
    if (file.size > 1024 * 1024 * 5) {
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        写真を追加
      </div>
    </div>
  );

  const getFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleRemoveImage = (img) => {
    // add field delete file
    if (form.getFieldValue(`${name}_delete`)) {
      form.getFieldValue(`${name}_delete`).push({
        imageId: img.imageId,
        deleteFlg: '1',
      });
    } else {
      form.setFieldValue(`${name}_delete`, [
        {
          imageId: img.imageId,
          deleteFlg: '1',
        },
      ]);
    }
  };
  useEffect(() => {
    setMaxCount(
      form.getFieldValue('pointImages')
        ? form.getFieldValue('pointImages').length + 5
        : 5,
    );
  }, []);
  return (
    <div style={{ maxWidth: '50vw' }}>
      <Form.Item
        getValueFromEvent={getFile}
        name={name}
        valuePropName="fileList"
      >
        <Upload
          listType="picture-card"
          defaultFileList={fileList}
          onPreview={handlePreview}
          beforeUpload={beforeUpload}
          maxCount={maxCount}
          multiple
          onRemove={flagRemove && handleRemoveImage}
        >
          {isEdit?.pointId
            ? maxCount !== lenghtFileList && uploadButton
            : !(fileListForm?.length === 5) && uploadButton}
        </Upload>
      </Form.Item>
      <Modal
        open={previewOpen}
        title={!noShowTitle && previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="preview" className="w-full" src={previewImage} />
      </Modal>
    </div>
  );
}

export default FormItemUpload;

FormItemUpload.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isEdit: PropTypes.bool,
};
