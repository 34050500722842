import dayjs from 'dayjs';

export const LAYOUT_FORM_ORDER = {
  labelCol: { sm: 7, xxl: 6 },
  wrapperCol: { sm: 12, xxl: 10 },
};
export const LAYOUT_WRAPPER_FULL = {
  wrapperCol: { sm: 17, xxl: 18 },
};
export const LAYOUT_MODAL_INVOICE = {
  labelCol: { span: '6' },
};

export const INIT_CARGO = {
  quantityUnitDiv: '01',
  packingDiv: '01',
  cargoNm: '一般',
};

export const DEFAULT_INITIAL_ORDER = {
  cargos: [INIT_CARGO],
  consolidationDiv: '01',
  pickupTimeDiv: '01',
  deliveryTimeDiv: '01',
  pickDeliTypeDiv: '01',
  pickDeliWayDiv: '01',
  orderWayDiv: '01',
  collectMoneyFlg: '0',
  pickupYmd: dayjs(),
  deliveryYmd: dayjs(),
};

export const DEFAULT_INITIAL_REGULAR = {
  cargos: [INIT_CARGO],
  consolidationDiv: '01',
  holidayExcludeFlg: '0',
  pickupTimeDiv: '01',
  deliveryTimeDiv: '01',
  pickDeliTypeDiv: '01',
  pickDeliWayDiv: '01',
};
