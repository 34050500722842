import React from 'react';
import { IconDropDown } from '@assets/icons';
import { Select } from 'antd';
import PropTypes from 'prop-types';

function MySelect({ listOptions, ...props }) {
  return (
    <Select suffixIcon={<IconDropDown className="anticon" />} {...props}>
      {listOptions?.map((item) => (
        <Select.Option
          key={item?.value}
          value={item?.value}
          knName={item?.baseNmKn}
        >
          {item?.label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default MySelect;

MySelect.propTypes = {
  listOptions: PropTypes.array,
};
