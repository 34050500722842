import {
  DATE_FORMAT_API,
  DATE_FORMAT_DISPLAY,
  TIME_DATE_FORMAT,
  TIME_FORMAT_API,
  TIME_FORMAT_NORMAL,
  YEAR_MONTH_FORMAT_API,
  YEAR_MONTH_FORMAT_API_2,
  YEAR_MONTH_FORMAT_DISPLAY,
} from '@common/constant/date';
import dayjs from 'dayjs';

export const formatDate = (value, typePicker) => {
  const dateMoment = dayjs(value, DATE_FORMAT_API).isValid()
    ? dayjs(value, DATE_FORMAT_API)
    : value;
  if (typePicker === 'month') {
    return dateMoment?.format(YEAR_MONTH_FORMAT_DISPLAY);
  }
  return dateMoment?.format(DATE_FORMAT_DISPLAY);
};
export const monthDisplay = (value) => {
  return value
    ? dayjs(value, YEAR_MONTH_FORMAT_API).format(YEAR_MONTH_FORMAT_DISPLAY)
    : '';
};
export const dateDisplay = (value) => {
  return value ? dayjs(value).format(DATE_FORMAT_DISPLAY) : '';
};
export const hourDisplay = (value) => {
  return value ? dayjs(value, TIME_FORMAT_API).format(TIME_FORMAT_NORMAL) : '';
};
export const timeDateDisplay = (value, format = TIME_DATE_FORMAT) => {
  return value ? dayjs(value).format(format) : '';
};
export const timeFormatApi = (value) => {
  return value ? dayjs(value).format(DATE_FORMAT_API) : '';
};
export const formatDateFilter = (value, typePicker) => {
  const formatType =
    typePicker === 'month' ? YEAR_MONTH_FORMAT_API : DATE_FORMAT_API;
  return value ? dayjs(value).format(formatType) : '';
};
export const formatYearMonth = (value) => {
  const formatType = YEAR_MONTH_FORMAT_API_2;
  return value ? dayjs(value).format(formatType) : '';
};
export const formatDateTime = (value) => {
  const dateTime = `${value.getUTCFullYear()}/${
    value.getUTCMonth() + 1
  }/${value.getUTCDate()} ${value.getUTCHours()}:${value.getUTCMinutes()}:${value.getUTCSeconds()}`;
  return dateTime;
};

export const formatDateOrder = (value, type = 'post') => {
  if (!dayjs(value, DATE_FORMAT_API).isValid()) {
    return value;
  }
  if (type === 'get') {
    return dayjs(value, DATE_FORMAT_API);
  }
  return dayjs(value, DATE_FORMAT_API).format(DATE_FORMAT_DISPLAY);
};

export const formatTimeOrder = (value, type = 'post') => {
  if (type === 'get') {
    return dayjs(value, TIME_FORMAT_NORMAL).isValid()
      ? dayjs(value, TIME_FORMAT_NORMAL)
      : value;
  }
  return dayjs(value, TIME_FORMAT_API).isValid()
    ? dayjs(value, TIME_FORMAT_API).format(TIME_FORMAT_NORMAL)
    : value;
};
export const formatTimeAttendances = (value) => {
  return value ? dayjs(value).format(TIME_FORMAT_NORMAL) : '';
};
export const formatTimeApi = (value) =>
  dayjs(value, TIME_FORMAT_API).isValid()
    ? dayjs(value, TIME_FORMAT_API).format(TIME_FORMAT_API)
    : value;

export const formatFieldsTime = (value) =>
  dayjs(value, TIME_FORMAT_API).isValid()
    ? dayjs(value, TIME_FORMAT_API)
    : value;

export const formatTimeNormal = (value) => {
  return value ? dayjs(value).format(TIME_FORMAT_NORMAL) : '';
};

export const getToday = () => dayjs();

export const getDayFromToday = (...add) => dayjs().add(...add);

export const dayjsToTime = (timeDayjs) => {
  return timeDayjs && timeDayjs.hour() + timeDayjs.minute() / 60;
};

export const getMonthBetween = (startMonth, endMonth) => {
  let betweenMonths = [];
  if (dayjs(startMonth) <= dayjs(endMonth)) {
    let month = dayjs(startMonth).startOf('month');

    while (month <= dayjs(endMonth).startOf('month')) {
      betweenMonths = [...betweenMonths, month.format('YYYY/MM')];
      month = month.add(1, 'month');
    }
  }
  return betweenMonths;
};
