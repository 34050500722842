import React from 'react';
import { IconWarning } from '@assets/icons';
import { formatDate, formatTimeAttendances } from '@common/utils/dateHelper';
import { Table } from 'antd';
import PropTypes from 'prop-types';

function TableDetailAttendances({
  data,
  handleChangePage,
  handleSortAttenDance,
  total,
  currentPage,
  setIsOpenCreateModal,
  setDataPost,
  setModalDelete,
  detailCompanyId,
  setIsEdit,
}) {
  const editAttendances = (record) => {
    setDataPost({ ...record, companyId: detailCompanyId });
    setIsOpenCreateModal(true);
    setIsEdit(true);
  };
  const deleteAttendances = (record) => {
    setModalDelete(true);
    setDataPost(record);
  };
  const columns = [
    {
      title: '日付',
      width: 180,
      dataIndex: 'workDate',
      sorter: { multiple: 1 },
      render: (_, record) => <div> {formatDate(record?.workDate, 'date')}</div>,
    },
    {
      title: '出勤',
      width: 120,
      dataIndex: 'goingDatetime',
      sorter: { multiple: 1 },
      render: (_, record) => (
        <div>
          {record?.goingDatetime ? (
            formatTimeAttendances(record?.goingDatetime)
          ) : (
            <div className="flex-align">
              <span className="fw-600 color-red pr-5"> 打刻なし</span>
              <IconWarning className="fill-danger" />
            </div>
          )}
        </div>
      ),
    },
    {
      title: '退勤',
      width: 120,
      dataIndex: 'leavingDatetime',
      sorter: { multiple: 1 },
      render: (_, record) => (
        <div>
          {record?.leavingDatetime ? (
            formatTimeAttendances(record?.leavingDatetime)
          ) : (
            <div className="flex-align">
              <span className="fw-600 color-red pr-5"> 打刻なし</span>
              <IconWarning className="fill-danger" />
            </div>
          )}
        </div>
      ),
    },
    {
      title: '休憩開始',
      width: 120,
      dataIndex: 'restStartDt',
      render: (_, record) => (
        <div>
          {record?.attendanceRests?.length ? (
            record?.attendanceRests?.map((x) => {
              return (
                <div className="flex-col-reverse ">
                  <span>{formatTimeAttendances(x.restStartDt)}</span>
                </div>
              );
            })
          ) : (
            <div className="flex-align">
              <span className="fw-600 color-red pr-5"> 打刻なし</span>
              <IconWarning className="fill-danger" />
            </div>
          )}
        </div>
      ),
    },
    {
      title: '休憩終了',
      width: 150,
      dataIndex: 'restEndDt',
      render: (_, record) => (
        <div>
          {record?.attendanceRests?.length ? (
            record?.attendanceRests?.map((x) => {
              return (
                <div className="flex-col-reverse ">
                  <span>{formatTimeAttendances(x.restEndDt)}</span>
                </div>
              );
            })
          ) : (
            <div className="flex-align">
              <span className="fw-600 color-red pr-5"> 打刻なし</span>
              <IconWarning className="fill-danger" />
            </div>
          )}
        </div>
      ),
    },
    {
      title: '休憩時間',
      width: 120,
      dataIndex: 'totalResTime',
      sorter: { multiple: 1 },
      render: (_, record) => <div>{record?.totalResTime}</div>,
    },
    {
      title: '勤務時間',
      width: 120,
      dataIndex: 'totalWorkTime',
      sorter: { multiple: 1 },
      render: (_, record) => <div>{record.totalWorkTime}</div>,
    },
    {
      width: 150,
      fixed: 'right',
      render: (_, record) => (
        <div className="flex-end dis-flex mr-25">
          <span
            className="mr-10 w-130 fw-14 pointer color-primary border-b-blue"
            onClick={() => editAttendances(record)}
          >
            修正
          </span>
          <span
            className="mr-10 w-130 fw-14 pointer color-red border-b-red"
            onClick={() => deleteAttendances(record)}
          >
            削除
          </span>
        </div>
      ),
    },
  ];
  const title = () =>
    total > 0 && (
      <div className="table-title">
        {data?.length}件表示/{total}件中
      </div>
    );

  return (
    <Table
      columns={columns}
      title={title}
      rowKey={(record) => record.attendanceId}
      pagination={{
        showSizeChanger: false,
        total,
        position: ['topRight'],
        pageSize: 20,
        onChange: handleChangePage,
        current: currentPage,
      }}
      onChange={handleSortAttenDance}
      dataSource={data}
      scroll={{ x: 400, y: 500 }}
    />
  );
}

export default TableDetailAttendances;
TableDetailAttendances.propTypes = {
  data: PropTypes.array,
  handleChangePage: PropTypes.func,
  handleSortAttenDance: PropTypes.func,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  setIsOpenCreateModal: PropTypes.func,
  setDataPost: PropTypes.func,
  setModalDelete: PropTypes.func,
  detailCompanyId: PropTypes.number,
  setIsEdit: PropTypes.func,
};
