import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IconAvatarDefault, IconDropDown, IconPlus } from '@assets/icons';
import { YEAR_MONTH_FORMAT_API_2 } from '@common/constant/date';
import { useAlert } from '@common/contexts/Alert';
import { useLayout } from '@common/contexts/Layout';
import { formatDateFilter, monthDisplay } from '@common/utils/dateHelper';
import { switchSortOrder } from '@common/utils/orderHelper';
import { MyDatePicker } from '@components/common';
import MyModal from '@components/common/MyModal';
import attenDanceApi from '@services/apis/attenDance';
import { useGetListAttenDance } from '@services/hooks/attenDance';
import { useGetListCompanies } from '@services/hooks/companies';
import { useGetDriverDetail } from '@services/hooks/driver';
import { useGetUserDetail } from '@services/hooks/users';
import { Avatar, Button, Col, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import dayjs from 'dayjs';
import ModalAttendance from './ModalAttendances';
import TableDetailAttenDance from './component/attendancesList/TableDetailAttendances';

function DetailAttenDance() {
  const { state } = useLocation();
  const monthDefault = formatDateFilter(state?.yearMonth, 'month');
  const { setIsLoading } = useLayout();
  const [params, setParams] = useState({
    skip: 0,
    workMonth: state?.yearMonth,
  });
  const { setAlert } = useAlert();
  const [formEdit] = useForm();
  const [listAttenDance, setListAttenDance] = useState([]);
  const [totalOrder, setTotalOrder] = useState();
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const {
    data: dataDetailAttenDance,
    isFetching,
    refetch,
  } = useGetListAttenDance({
    ...params,
    mUserId: state?.mUserId,
  });
  const { data: dataDetailUserId } = useGetUserDetail(state?.userId);
  const { data: dataDriverDetail } = useGetDriverDetail(
    dataDetailUserId?.driverId,
  );

  const [currentPage, setCurrentPage] = useState();
  const [isFilter, setIsFilter] = useState(false);
  const [dataPost, setDataPost] = useState();
  const { data: dataCompanyList } = useGetListCompanies({
    take: 1000,
  });
  const [modaldelete, setModalDelete] = useState(false);
  const companyList = useMemo(
    () =>
      dataCompanyList?.data?.map((e) => ({
        label: e.companyNm,
        value: e.companyId,
        ...e,
      })),
    [dataCompanyList],
  );
  const handleChangePage = (newPage) => {
    setParams({ ...params, skip: (newPage - 1) * 20 });
    setCurrentPage(newPage);
  };
  const handleSortAttenDance = (pagination, _, sorter) => {
    let sortArr;
    if (pagination.current === params.skip / 20 + 1) {
      if (sorter.column) {
        sortArr = switchSortOrder(sorter);
        setParams({ ...params, sort: sortArr.join(',') });
      } else if (Array.isArray(sorter)) {
        sortArr = sorter.map((sortItem) => switchSortOrder(sortItem));
        setParams({ ...params, sort: sortArr.join(',') });
      } else {
        sortArr = [];
        const paramsConvert = {
          ...params,
          skip: params.skip,
        };
        delete paramsConvert.sort;
        setParams({ ...paramsConvert });
      }
    }
  };

  const handleCloseEditModal = () => {
    formEdit.resetFields();
    setIsOpenCreateModal(false);
    setIsEdit(false);
  };
  const handleChangeDatePicker = (dateSelect) => {
    setParams({
      ...params,
      workMonth: dateSelect.format(YEAR_MONTH_FORMAT_API_2),
    });
  };

  const handleCancelDeleteModal = () => {
    setModalDelete(null);
  };
  const handleDeleteAttenDances = async () => {
    await attenDanceApi
      .deleteAttenDance(dataPost?.attendanceId)
      .then(() => {
        refetch();
        setAlert({ msg: '勤怠情報を削除しました。', type: 'success' });
        setModalDelete(false);
      })
      .catch((e) => console.log(e));
  };
  const handleOpenCreateAttenDances = () => {
    setIsEdit(false);
    setIsOpenCreateModal(true);
  };
  useEffect(() => {
    if (dataDetailAttenDance) {
      setListAttenDance(dataDetailAttenDance.datas);
      setTotalOrder(dataDetailAttenDance.total);
    }
    if (isFilter || !isFetching) {
      setIsLoading(isFetching);
      setIsFilter(false);
    }
  }, [dataDetailAttenDance, isFetching, isFilter]);

  return (
    <>
      <ModalAttendance
        setAlert={setAlert}
        isOpenCreateModal={isOpenCreateModal}
        handleCloseCreateModal={handleCloseEditModal}
        dataCompanyList={companyList}
        refetch={refetch}
        form={formEdit}
        initialvalue={dataPost}
        isEdit={isEdit}
        dataDetailUserId={dataDetailUserId}
      />
      <MyModal
        isShowModal={modaldelete}
        title="この勤怠情報を削除しますか？"
        handleOk={handleDeleteAttenDances}
        handleCancel={handleCancelDeleteModal}
      />
      <div className="list-order">
        <Row justify="space-between">
          <h1 className="fz-32 fw-700">勤怠管理</h1>
          <Button
            onClick={() => handleOpenCreateAttenDances()}
            className="flex-center w-120"
            size="small"
            type="primary"
          >
            <IconPlus className="mr-10" /> 新規登録
          </Button>
        </Row>
        <Row className="time-attendance  py-43 mt-16 form-filter-order">
          <Row className="flex-col">
            <Col className="flex-start">ユーザID</Col>
            <Col>{dataDetailUserId?.userId}</Col>
          </Row>
          <Row className="flex-col pl-100">
            <Col>ユーザ名</Col>
            <Col>
              <div className="flex-center">
                <Avatar
                  className="flex-center"
                  src={dataDriverDetail?.avatar}
                  icon={<IconAvatarDefault />}
                  size={25}
                />
                <span className="pl-10">{dataDetailUserId?.userNm}</span>
              </div>
            </Col>
          </Row>
          <Row className="flex-col pl-100">
            <Col className="flex-right">運送会社名</Col>
            <Col>{dataDetailUserId?.companyNm}</Col>
          </Row>
        </Row>
        <Row className="time-attendance py-10 mt-16 bg-gray">
          <Col className="flex-center" xs={2} sm={2} xxl={1} span={4}>
            表示月
          </Col>
          <Col xs={2} sm={3}>
            <MyDatePicker
              format={(value) => monthDisplay(value)}
              showToday
              picker="month"
              suffixIcon={<IconDropDown className="anticon" />}
              disabledDate={false}
              defaultValue={dayjs(monthDefault)}
              onChange={handleChangeDatePicker}
              allowClear={false}
            />
          </Col>
          <Col xs={3} sm={3} xxl={2} className="flex-center">
            合計勤務時間
          </Col>
          <Col className="flex-center">
            <span className="fw-600 fz-24">
              {dataDetailAttenDance?.totalWorkTime}
            </span>
          </Col>
          <Col xs={3} sm={3} xxl={2} className="flex-center">
            合計休憩時間
          </Col>
          <Col className="flex-center">
            <span className="fw-600 fz-24">
              {dataDetailAttenDance?.totalResTime}
            </span>
          </Col>
        </Row>
        <TableDetailAttenDance
          currentPage={currentPage}
          handleSortAttenDance={handleSortAttenDance}
          handleChangePage={handleChangePage}
          data={listAttenDance}
          total={totalOrder}
          setIsOpenCreateModal={setIsOpenCreateModal}
          setDataPost={setDataPost}
          companyId={dataDriverDetail?.companyId}
          setModalDelete={setModalDelete}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
        />
      </div>
    </>
  );
}

export default DetailAttenDance;
