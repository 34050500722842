import React from 'react';
import { IconMaintenanceLogo } from '@assets/icons';

function Maintenance() {
  return (
    <div className="text-center vertical-center">
      <IconMaintenanceLogo className="mb-40" />
      <h1 className="fw-700 mb-30">ただいまメンテナンス中です</h1>
      <h3 className="fw-450">メンテナンス終了までしばらくお待ちください</h3>
    </div>
  );
}

export default Maintenance;
