import React, { useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Button, Modal, Row, Slider, Upload } from 'antd';
import PropTypes from 'prop-types';

const footerDefault = (handleOk, handleCancel, disabled) => (
  <Row align="middle" justify="space-between">
    <Button onClick={handleCancel}>キャンセル</Button>
    <Button disabled={disabled} onClick={handleOk} type="primary">
      完了
    </Button>
  </Row>
);
function MyAvatarEditor({
  isShowModal,
  setIsShowModal,
  image,
  setImage,
  handleOk,
  handleCancel,
  ...props
}) {
  const [scaleValue, setScaleValue] = useState(1);
  const [isShowImgDef, setIsShowImgDef] = useState(true);
  const [isOnChangeSlider, setIsOnChangeSlider] = useState(true);
  const {
    avatar,
    width = 400,
    footer = footerDefault(handleOk, handleCancel, !avatar && isShowImgDef),
  } = {
    ...props,
  };
  const onChange = (newValue) => {
    setIsOnChangeSlider(false);
    setScaleValue(newValue);
  };
  const beforeUpload = (file) => {
    if (file.size > 1024 * 1024 * 5) {
      return Upload.LIST_IGNORE;
    }
    setImage(URL.createObjectURL(file));
    setIsShowImgDef(false);
    return false;
  };
  useEffect(() => {
    setIsShowImgDef(true);
    setScaleValue(1);
  }, [isShowModal]);

  return (
    <Modal
      open={isShowModal}
      footer={footer}
      width={width}
      onCancel={handleCancel}
      bodyStyle={{ paddingTop: '50px' }}
      maskClosable={isOnChangeSlider}
    >
      <AvatarEditor
        ref={(imgEdit) => setImage(imgEdit)}
        image={image || ''}
        width={250}
        height={250}
        border={50}
        borderRadius={120}
        color={[178, 178, 178, 0.7]}
        scale={scaleValue}
        rotate={0}
        className={!avatar && isShowImgDef && 'bg-image-default'}
        crossOrigin="anonymous"
      />
      <Row className="flex-center">
        <Slider
          min={1}
          max={2}
          step={0.01}
          onChange={onChange}
          className="w-240 form-filter-border py-3"
          tooltip={{ open: false }}
          value={typeof scaleValue === 'number' ? scaleValue : 1}
          onAfterChange={() =>
            setTimeout(() => {
              setIsOnChangeSlider(true);
            }, 100)
          }
        />
      </Row>
      <Row className="flex-center">
        <Upload
          accept="image/*"
          showUploadList={false}
          beforeUpload={beforeUpload}
        >
          <span className="pointer">+ 画像アップロード</span>
        </Upload>
      </Row>
    </Modal>
  );
}

export default MyAvatarEditor;

MyAvatarEditor.propTypes = {
  isShowModal: PropTypes.bool,
  setIsShowModal: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setImage: PropTypes.func,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
};
