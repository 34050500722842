import React, { useEffect, useMemo, useState } from 'react';
import { IconPlus } from '@assets/icons';
import { rulesAttenDance, validateMessages } from '@common/constant/rules';
import FormItem from '@components/form/FormItem';
import userApi from '@services/apis/users';
import { Button, Col, Form, Row } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

function FormAttendanceCreate({
  form,
  handleSubmitForm,
  initialValues,
  dataCompanyList,
  isEdit,
  dataDetailUserId,
}) {
  const [dataUser, setDataUser] = useState();
  const userList = useMemo(
    () =>
      dataUser?.data?.map((e) => ({
        label: e.userNm,
        value: e.mUserId,
        ...e,
      })),
    [dataUser],
  );
  const handleOnValuesChange = async (changedValues) => {
    const changedName = Object.getOwnPropertyNames(changedValues)?.at(0);
    if (changedName === 'goingDatetime') {
      form.validateFields(['leavingDatetime']);
    } else if (changedName === 'attendanceRests') {
      const idxRest = changedValues[changedName].length - 1;
      if (changedValues[changedName][idxRest]?.restStartDt) {
        form.validateFields([['attendanceRests', idxRest, 'restEndDt']]);
      }
    } else if (changedName === 'companyId') {
      await userApi
        .getListUsers({ companyId: changedValues?.companyId })
        .then((response) => {
          setDataUser(response);
        });
    }
  };

  const dataAttendanceRests = () => {
    const obj = initialValues?.attendanceRests?.map((x) => {
      return {
        restId: x?.restId,
        restStartDt: x?.restStartDt && dayjs(x?.restStartDt),
        restEndDt: x?.restEndDt && dayjs(x?.restEndDt),
      };
    });
    return obj;
  };
  const dataCompanyDefault = {
    label: dataDetailUserId?.companyNm,
    value: dataDetailUserId?.companyId,
  };
  const dataUserDefault = {
    label: dataDetailUserId?.userNm,
    value: dataDetailUserId?.mUserId,
  };

  useEffect(() => {
    const { goingDatetime, leavingDatetime } = initialValues || {};

    form.setFieldsValue({
      goingDatetime: isEdit && goingDatetime ? dayjs(goingDatetime) : undefined,
      leavingDatetime:
        isEdit && leavingDatetime ? dayjs(leavingDatetime) : undefined,
      attendanceRests: isEdit ? dataAttendanceRests() : undefined,
      companyId: dataCompanyDefault,
      mUserId: dataDetailUserId ? dataUserDefault : undefined,
      workDate: isEdit ? initialValues?.workDate : undefined,
    });
  }, [initialValues, isEdit, dataDetailUserId]);

  return (
    <Form
      form={form}
      onFinish={handleSubmitForm}
      validateMessages={validateMessages}
      labelCol={{ flex: '45px' }}
      onValuesChange={handleOnValuesChange}
    >
      <Row className="form-create-attendance form-item" align="top">
        <Col span={7}>
          <FormItem
            label="日付"
            borderless
            itemType="datepicker"
            name="workDate"
            disabled={isEdit}
            rules={rulesAttenDance.workdate}
          />
        </Col>
        <Col className="pl-15" span={7}>
          <FormItem
            itemType="select"
            borderless
            name="companyId"
            label="運送会社"
            listOptions={dataCompanyList}
            disabled={Boolean(dataDetailUserId)}
          />
        </Col>
        <Col className="pl-15" span={7}>
          <FormItem
            inputWidth="w-full"
            itemType="select"
            name="mUserId"
            listOptions={dataUser ? userList : null}
            label="ユーザ名"
            borderless
            disabled={Boolean(dataDetailUserId)}
            rules={!isEdit && rulesAttenDance.userNm}
          />
        </Col>
      </Row>
      <div id="item_infor" className="borderless">
        <Row>
          <Col span={10}>
            <FormItem
              itemType="timeAttendances"
              name="goingDatetime"
              label="出勤"
              borderless
              rules={rulesAttenDance.goingDatetime}
              layoutForm={{
                labelCol: { flex: '150px' },
              }}
            />
          </Col>
          <Col span={10}>
            <FormItem
              itemType="timeAttendances"
              name="leavingDatetime"
              label="退勤"
              borderless
              rules={rulesAttenDance.leavingDatetime}
              layoutForm={{
                labelCol: { flex: '150px' },
              }}
            />
          </Col>
        </Row>
      </div>
      <Form.List name="attendanceRests">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => {
                // to do: remove set state in formlist
                const currentListAtdRest =
                  form.getFieldValue('attendanceRests');
                const deleteFlg = currentListAtdRest[index]?.deleteFlg === '1';

                const handleRemoveAtdRest = () => {
                  if (currentListAtdRest[index]?.restId) {
                    const newListAtdRest = currentListAtdRest.map(
                      (item, idx) => {
                        if (idx === index) {
                          return { ...item, deleteFlg: '1' };
                        }
                        return item;
                      },
                    );
                    form.setFieldsValue({
                      attendanceRests: newListAtdRest,
                    });
                  } else {
                    remove(field.name);
                  }
                };

                return (
                  !deleteFlg && (
                    <div key={index} id="item_infor">
                      <Row
                        className={` ${
                          fields?.length === index + 1 ? 'mb-20' : 'borderless'
                        }`}
                      >
                        <Col span={10}>
                          <FormItem
                            itemType="timeAttendances"
                            name={[index, 'restStartDt']}
                            label="休憩開始"
                            layoutForm={{
                              labelCol: { flex: '150px' },
                            }}
                            borderless
                          />
                        </Col>
                        <Col span={10}>
                          <FormItem
                            itemType="timeAttendances"
                            name={[index, 'restEndDt']}
                            label="休憩終了"
                            rules={rulesAttenDance.restEndDt(index)}
                            layoutForm={{
                              labelCol: { flex: '150px' },
                            }}
                            borderless
                          />
                        </Col>
                        <Col className="flex-align pt-16 h-64" span={4}>
                          <div
                            onClick={handleRemoveAtdRest}
                            className="py-20 pointer color-red"
                          >
                            削除
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )
                );
              })}
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  className="w-full h-48 px-24 fz-20 fw-700 py-10"
                  type="dashed"
                  onClick={() => add()}
                >
                  <Row justify="center" align="middle">
                    <IconPlus className="fill-primary mr-15" />
                    <div className="fw-500">打刻を追加</div>
                  </Row>
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Form>
  );
}

export default FormAttendanceCreate;

FormAttendanceCreate.propTypes = {
  form: PropTypes.any,
  handleSubmitForm: PropTypes.func,
  initialValues: PropTypes.object,
  dataCompanyList: PropTypes.array,
  isEdit: PropTypes.bool,
  dataDetailUserId: PropTypes.object,
};
