import api from './baseApi';

const userApi = {
  getListUsers(params) {
    const childPath = new URLSearchParams(params)
      .toString()
      .replaceAll('+', '%20');
    return api.get(`/m_users?${childPath}`);
  },
  getUserDetail(id) {
    return api.get(`/m_users/${id}`);
  },
  postUser(data) {
    return api.post('/m_users', data);
  },

  updateUser(data, id) {
    return api.patch(`/m_users/${id}`, data);
  },

  deleteUser(id) {
    return api.delete(`/m_users/${id}`);
  },
};

export default userApi;
