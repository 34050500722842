import { useQueries, useQuery } from 'react-query';
import DIV_NAME from '@common/constant/masterData';
import masterDataApi from '@services/apis/masterData';

export const useGetDivs = (enabled) => {
  const arrValueDivs = Object.values(DIV_NAME);
  const queries = useQueries(
    arrValueDivs.map((valueDiv) => ({
      queryKey: valueDiv,
      queryFn: () => masterDataApi.getDiv(valueDiv),
      staleTime: Infinity,
      enabled,
    })),
  );
  const dataConverted =
    queries?.map((query) => {
      return query?.data?.map((e) => ({
        label: e.divValueNm,
        value: e.divValue,
      }));
    }) || [];

  const [
    rolesDiv,
    invoiceDeadlineDiv,
    paymentDeadlineDiv,
    workFormDiv,
    quantityUnitDiv,
    packingDiv,
    orderWayDiv,
    timeDiv,
    dowDiv,
    consolidationDiv,
    statusDiv,
    serviceStatusDiv,
    serviceYmdDiv,
    operatingDaySearchCategoryDiv,
    workKindsDiv,
    allocationStatusDiv,
    formDiv,
    formOutputDiv,
    closingMonthDiv,
    orderLinkage,
    workStatusDiv,
    baseType,
    pickDeliTypeDiv,
    pickDeliWayDiv,
    deadlineMonth,
    deadlineDay,
    authRoleDiv,
    collectMoneyDiv,
    calcWayDiv,
    barcodeStatusDiv,
  ] = dataConverted;
  return {
    rolesDiv,
    invoiceDeadlineDiv,
    paymentDeadlineDiv,
    workFormDiv,
    quantityUnitDiv,
    packingDiv,
    orderWayDiv,
    timeDiv,
    dowDiv,
    consolidationDiv,
    statusDiv,
    serviceStatusDiv,
    serviceYmdDiv,
    operatingDaySearchCategoryDiv,
    workKindsDiv,
    allocationStatusDiv,
    formDiv,
    formOutputDiv,
    closingMonthDiv,
    orderLinkage,
    workStatusDiv,
    baseType,
    pickDeliTypeDiv,
    pickDeliWayDiv,
    deadlineMonth,
    deadlineDay,
    authRoleDiv,
    collectMoneyDiv,
    calcWayDiv,
    barcodeStatusDiv,
  };
};

export const useGetCustomers = () => {
  const { data } = useQuery('cutomers', masterDataApi.getCustomers);
  const listCustomer = data?.data?.map((e) => ({
    label: e.customerNm,
    value: e.customerId,
  }));
  return listCustomer;
};

export const useGetBases = (params) => {
  const { data } = useQuery(
    ['bases', params],
    () => masterDataApi.getBases({ ...params, take: 1000 }),
    // { staleTime: Infinity },
  );
  const listBase = data?.data?.map((e) => ({
    label: e.baseNm,
    value: e.baseId,
    ...e,
  }));
  return listBase;
};

export const useGetRoutes = () => {
  const { data } = useQuery('routes', masterDataApi.getRoutes);
  const listRoute = data?.map((e) => ({
    label: e.routeNm,
    value: e.routeId,
    ...e,
  }));
  return listRoute;
};

export const useGetCompanies = () => {
  const { data } = useQuery('companies', masterDataApi.getCompanies);
  const listCompanies = data?.data?.map((e) => ({
    label: e.companyNm,
    value: e.companyId,
    ...e,
  }));
  return listCompanies;
};
export const useGetDrivers = () => {
  const { data } = useQuery('drivers', masterDataApi.getDrivers);
  const listDrivers = data?.data?.map((e) => ({
    label: e.driverNm,
    value: e.driverId,
    ...e,
  }));
  return listDrivers;
};

export const useGetMServices = (params) => {
  return useQuery(['mServices', params], () =>
    masterDataApi.getMServices(params),
  );
};
export const useGetSlots = (params) => {
  return useQuery(['slots', params], () => masterDataApi.getSlots(params));
};

export const useGetCarType = (params) => {
  const { data } = useQuery(
    ['carType', params],
    () => masterDataApi.getCarType(params),
    {
      staleTime: Infinity,
    },
  );
  const listCarType = data?.map((e) => ({
    label: e.cartypeNm,
    value: e.cartypeId,
    ...e,
  }));
  return listCarType;
};
