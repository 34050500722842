import React, { useState } from 'react';
import { IconMainLogo } from '@assets/icons';
import { Col, Row } from 'antd';
import { FormLogin, FormResetPassword, ResetPwdInfo } from './components';

function Login() {
  const [formState, setFormState] = useState('login');

  const handleDisplayResetPwdForm = () => {
    setFormState('resetPwd');
  };

  const handleResetPwd = () => {
    setFormState('resetPwdInfo');
  };

  const handleBackLogin = () => {
    setFormState('login');
  };

  const renderForm = () => {
    let stateForm;
    switch (formState) {
      case 'login':
        stateForm = (
          <FormLogin handleDisplayResetPwdForm={handleDisplayResetPwdForm} />
        );
        break;
      case 'resetPwd':
        stateForm = (
          <FormResetPassword
            handleResetPwd={handleResetPwd}
            handleBackLogin={handleBackLogin}
          />
        );
        break;
      default:
        stateForm = <ResetPwdInfo handleBackLogin={handleBackLogin} />;
    }
    return stateForm;
  };

  return (
    <Row className="login" align="middle">
      <Col span={10} className="text-center">
        <Row justify="center">
          <Col span={18}>
            <IconMainLogo className="mb-30" />
            {renderForm()}
          </Col>
        </Row>
      </Col>
      <Col span={14} className="background-img" />
    </Row>
  );
}

export default Login;
