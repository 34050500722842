import React, { createContext, useContext, useState } from 'react';

const LayoutContext = createContext();

export const useLayout = () => {
  const { collapsed, setCollapsed } = useContext(LayoutContext);
  const { isLoading, setIsLoading } = useContext(LayoutContext);
  return { collapsed, setCollapsed, isLoading, setIsLoading };
};

// eslint-disable-next-line react/prop-types
function LayoutProvider({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const value = React.useMemo(() => {
    return { collapsed, setCollapsed, isLoading, setIsLoading };
  }, [collapsed, isLoading]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
}

export default LayoutProvider;
