import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import AlertProvider from '@common/contexts/Alert';
import LayoutProvider from '@common/contexts/Layout';
import store from '@store';
import { ConfigProvider, Empty } from 'antd';
import locale from 'antd/lib/locale/ja_JP';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import persistStore from 'redux-persist/es/persistStore';
import packageJson from '../package.json';
import App from './App';
import './styles/index.scss';
import './styles/theme.less';

console.log(`version: ${packageJson.version}`);

dayjs.locale('ja');

const persistor = persistStore(store);
const queryClient = new QueryClient();

const MemoizedApp = React.memo(App);

ReactDOM.render(
  <ConfigProvider
    renderEmpty={() => (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="適当な検索結果がありません"
      />
    )}
    locale={locale}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <LayoutProvider>
            <AlertProvider>
              <MemoizedApp />
              <ToastContainer
                closeButton={false}
                autoClose={5000}
                hideProgressBar
                position="top-center"
              />
            </AlertProvider>
          </LayoutProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root'),
);
