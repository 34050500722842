import React, { useState } from 'react';
import { IconAvatarDefault } from '@assets/icons';
import MyAvatarEditor from '@components/common/MyAvatarEditor';
import { Avatar, Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';

function FormAvatarEdit({ name, ...props }) {
  const { isEdit } = { ...props };
  const [isShowModal, setIsShowModal] = useState(false);
  const form = Form.useFormInstance();
  const [image, setImage] = useState(form.getFieldValue('avatar'));
  const avatar = form.getFieldValue('avatar');

  const handleOk = () => {
    if (image) {
      const canvasScaled = image.getImageScaledToCanvas();
      canvasScaled.toBlob((blob) => {
        if (form) {
          const file = new File([blob], 'avatar.jpg', { type: 'image/jpeg' });
          form.setFieldValue('avatar', file);
          setIsShowModal(false);
          setImage(file);
        }
      }, 'image/jpeg');
    }
  };
  const handleCancel = () => {
    setIsShowModal(false);
    setImage();
  };
  const handleShowModal = () => {
    if (avatar) {
      setImage(avatar);
    }
    setIsShowModal(true);
  };
  const handleRemoveImage = () => {
    form.setFieldValue('avatar', 1);
    setImage();
  };

  return (
    <Row align="middle">
      <MyAvatarEditor
        setIsShowModal={setIsShowModal}
        isShowModal={isShowModal}
        image={image}
        setImage={setImage}
        handleOk={handleOk}
        avatar={avatar}
        handleCancel={handleCancel}
      />
      <Form.Item name={name} valuePropName="filelist">
        <Row align="middle">
          <Col className="mr-24">
            <Avatar
              src={avatar?.lastModified ? URL.createObjectURL(avatar) : avatar}
              icon={<IconAvatarDefault />}
              size={80}
            />
          </Col>
          <span
            onClick={handleShowModal}
            className="fw-400 fz-16 mr-20 color-primary pointer"
          >
            {isEdit ? '画像を差し替え' : '画像を登録'}
          </span>
          {avatar && (
            <span
              onClick={handleRemoveImage}
              className="fw-400 fz-16 color-red pointer"
            >
              削除
            </span>
          )}
        </Row>
      </Form.Item>
    </Row>
  );
}

export default FormAvatarEdit;
FormAvatarEdit.propTypes = {
  name: PropTypes.string,
};
