import cache from '@common/utils/cache';
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'auth',
  initialState: {
    token: cache.get('token'),
    refreshToken: cache.get('refreshToken'),
    error: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.token = action.payload && action.payload.token;
      state.refreshToken = action.payload && action.payload.refreshToken;
      cache.set('token', action.payload.token);
      cache.set('refreshToken', action.payload.refreshToken);
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.token = null;
      state.refreshToken = null;
      cache.remove('token');
      cache.remove('refreshToken');
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginSuccess, logoutSuccess, setError } = slice.actions;

export const logoutAction = () => async (dispatch) => {
  dispatch(logoutSuccess());
};

// Get value from state
export const authStore = (state) => state.auth;

export default slice.reducer;
