/* eslint-disable prefer-regex-literals */
import { dayjsToTime } from '@common/utils/dateHelper';
import { getRangeTimeToCompare } from '@common/utils/orderHelper';
import dayjs from 'dayjs';

/* eslint-disable no-template-curly-in-string */
export const REGEX = {
  NUMBER: new RegExp(/^[0-9]+$/),
  TEL: new RegExp(/^[0-9]+$/),
  TEXT_WITHOUT_JAPANESE: new RegExp(/^[a-zA-Z0-9!@#\\$%\\^\\&*\\)\\(+=._-]+$/g),
  SIMPLE_EMAIL: new RegExp(/\S+@\S+\.\S+/),
  DECIMAL_2_PLACES: new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/),
  WEIGHT: new RegExp(/^\d{1,5}(\.\d{1,2})?$/),
  SIZE: new RegExp(/^[0-9]{1,3}$/),
  LAT: new RegExp(/^(-?[1-8]?\d(?:\.\d{1,14})?|90(?:\.0{1,14})?)$/),
  LNG: new RegExp(/^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,14})?|180(?:\.0{1,14})?)$/),
  HOUR_AMOUNT: new RegExp(/^([1-9]?[0-9])(\.[0-9]{1,2})?$/),
  TEXT_PASSWORD: new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^\w\s]).{12,}$/),
  HIRAGANA: new RegExp(/^([ぁ-ん]|ー)+$/),
};

export const validateMessages = {
  required: '${label}は必須入力です。',
  string: {
    min: '${min} 文字以上で入力してください',
    max: '入力可能な文字数を超えています。${max}文字以下で入力して下さい。',
    range: '${min} 文字以上${max} 文字以下で入力してください',
  },
};

export const rulesOrder = {
  customerId: [{ required: true }],
  formerOrderId: [{ max: 50 }],
  routeId: [{ required: true }],
  pickDeliTypeDiv: [{ required: true }],
  pickDeliWayDiv: [{ required: true }],
  regularNm: [{ required: true }, { max: 128 }],
  collectMoneyAmount: [
    { message: '利用できない文字が含まれています。', pattern: REGEX.NUMBER },
  ],
  memo: [{ max: 256 }],
  fromTerm: [
    { required: true },
    () => ({
      validator(_, value) {
        if (value && value.diff(dayjs(), 'day') < 0) {
          return Promise.reject(new Error('当日以降を指定してください。'));
        }
        return Promise.resolve();
      },
    }),
  ],
  toTerm: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value && value.diff(getFieldValue('fromTerm'), 's') <= 1) {
          return Promise.reject(
            new Error(
              '有効期間TOは有効期間FROMより後の日付を入力してください。',
            ),
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  pickupBaseId: [{ required: true }],
  pickupPointId: [{ required: true }],
  pickupName: [{ required: true }, { max: 128 }],
  pickupTelNumber: [
    {
      max: 11,
      message: ' 入力可能な文字数を超えています。11文字以下で入力して下さい。',
    },
    {
      message: '${label}は半角数字で入力してください。',
      pattern: REGEX.TEL,
    },
  ],
  pickupPostCd: [
    () => ({
      validator(_, value) {
        if (value && value.length !== 7) {
          return Promise.reject(new Error('7文字で入力して下さい。'));
        }
        return Promise.resolve();
      },
    }),
    {
      message: '${label}は半角英数字で入力してください。',
      pattern: REGEX.NUMBER,
    },
  ],
  pickupYmd: [{ required: true }],
  pickupTime: [
    () => ({
      validator(_, value) {
        if (value?.at(0) && value?.at(1)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('希望時間帯は必須入力です。'));
      },
    }),
  ],
  deliveryName: [{ required: true }, { max: 128 }],
  deliveryBaseId: [{ required: true }],
  deliveryPointId: [{ required: true }],
  deliveryTelNumber: [
    {
      max: 11,
      message: ' 入力可能な文字数を超えています。11文字以下で入力して下さい。',
    },
    {
      message: '${label}は半角数字、ハイフンで入力してください。',
      pattern: REGEX.TEL,
    },
  ],
  deliveryPostCd: [
    () => ({
      validator(_, value) {
        if (value && value.length !== 7) {
          return Promise.reject(new Error('7文字で入力して下さい。'));
        }
        return Promise.resolve();
      },
    }),
    {
      message: '${label}は半角英数字で入力してください。',
      pattern: REGEX.NUMBER,
    },
  ],
  deliveryYmd: [
    { required: true },
    ({ getFieldValue }) => ({
      validator(_, value) {
        const diffMiliseconds =
          getFieldValue('pickupYmd') &&
          value &&
          getFieldValue('pickupYmd').diff(value);
        if (diffMiliseconds > 0) {
          return Promise.reject(
            new Error('配達日付は集荷日付以降の日付を入力して下さい'),
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  deliveryTime: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        const timePickup = getFieldValue('pickupTime');
        const timePickupDiv = getFieldValue('pickupTimeDiv');
        const deliveryStart = dayjsToTime(value?.at(0));
        const deliveryEnd = dayjsToTime(value?.at(1));
        const [pickupStart, pickupEnd] = getRangeTimeToCompare(
          timePickupDiv,
          timePickup,
        );
        if (pickupStart > deliveryStart || pickupEnd > deliveryEnd) {
          return Promise.reject(
            new Error(
              '配達希望時間帯は集荷希望時間帯より後の時間を入力してください。',
            ),
          );
        }
        if (!value?.at(0) && !value?.at(1)) {
          return Promise.reject(new Error('希望時間帯は必須入力です。'));
        }
        return Promise.resolve();
      },
    }),
  ],
  deliveryTimeDiv: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        const timePickup = getFieldValue('pickupTime');
        const timePickupDiv = getFieldValue('pickupTimeDiv');
        const [pickupStart, pickupEnd] = getRangeTimeToCompare(
          timePickupDiv,
          timePickup,
        );
        const [deliveryStart, deliveryEnd] = getRangeTimeToCompare(value);
        if (pickupStart > deliveryStart || pickupEnd > deliveryEnd) {
          return Promise.reject(
            new Error(
              '配達希望時間帯は集荷希望時間帯より後の時間を入力してください。',
            ),
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  cargoNm: [{ required: true }, { max: 128 }],
  quantity: [
    { message: '利用できない文字が含まれています。', pattern: REGEX.NUMBER },
  ],
  weight: [
    {
      message: '重量の入力が正しくありません',
      pattern: REGEX.WEIGHT,
    },
  ],
  size: [
    {
      message: 'サイズの入力が正しくありません',
      pattern: REGEX.SIZE,
    },
  ],
  dowFlg: [{ required: true }],
};

export const rulesChangePwd = {
  password: [
    {
      required: true,
    },
  ],
  newPassword: [
    {
      required: true,
    },
    {
      message:
        'パスワードは12桁以上、大文字・小文字・記号の組合せを指定してください。',
      pattern: REGEX.TEXT_PASSWORD,
    },
  ],
  confirmNewPassword: [
    {
      required: true,
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('newPassword') === value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error('入力されたパスワードが一致しません。'),
        );
      },
    }),
  ],
};

export const ruleCreateServiceBulk = {
  rangeTime: [
    {
      required: true,
      message: '時間は必須入力です。',
    },
  ],
  companyId: [
    {
      required: true,
      message: '業者は必須入力です。',
    },
  ],
  cartypeId: [
    {
      required: true,
      message: '車両タイプは必須入力です。',
    },
  ],
  dowDiv: [
    {
      required: true,
      message: '希望曜日は必須入力です。',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        const unCheck =
          !getFieldValue('dayWeek') || !getFieldValue('dayWeek').at(0);
        if (value === '04' && unCheck) {
          return Promise.reject(new Error('曜日指定は必須入力です。'));
        }
        return Promise.resolve();
      },
      validateTrigger: 'onSubmit',
    }),
  ],
  fromTerm: [
    {
      required: true,
      message: '期間開始は必須入力です。',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          value &&
          getFieldValue('toTerm') &&
          value.startOf('d').diff(getFieldValue('toTerm').startOf('d'), 'd') > 0
        ) {
          return Promise.reject(new Error('終了日より前の日付を選択。'));
        }
        return Promise.resolve();
      },
    }),
  ],
  toTerm: [
    {
      required: true,
      message: '期間終了は必須入力です。',
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (
          value &&
          getFieldValue('fromTerm') &&
          value.startOf('d').diff(getFieldValue('fromTerm').startOf('d'), 'd') <
            0
        ) {
          return Promise.reject(new Error('開始日より後の日付を選択。'));
        }
        return Promise.resolve();
      },
    }),
  ],
  serviceYmd: [
    {
      required: true,
      message: '日付指定は必須入力です。',
    },
  ],
};

export const rulesBase = {
  baseNm: [
    {
      required: true,
      message: '${label}は必須入力です。',
    },
    {
      max: 128,
      message: '入力可能な文字数を超えています。128文字以下で入力して下さい。',
    },
  ],
  baseNmKn: [
    {
      required: true,
      message: '${label}は必須入力です。',
    },
    {
      max: 128,
      message: '入力可能な文字数を超えています。128文字以下で入力して下さい。',
    },
    {
      pattern: REGEX.HIRAGANA,
      message: '${label}はひらがなで入力してください。',
    },
  ],
  baseInfomation: [{ max: 256 }],
};

export const rulesPoint = {
  baseNm: [
    {
      required: true,
    },
    { max: 128 },
  ],
  pointNm: [
    {
      required: true,
      message: '${label}は必須入力です。',
    },
    {
      max: 128,
      message: '入力可能な文字数を超えています。128文字以下で入力して下さい。',
    },
  ],
  latitude: [
    {
      max: 18,
      message: '緯度は0～90の小数を入力してください。',
      pattern: REGEX.LAT,
    },
  ],
  longitude: [
    {
      max: 18,
      message: '経度は0～180の小数を入力してください。',
      pattern: REGEX.LNG,
    },
  ],
  telNumber: [
    {
      max: 11,
      message: '入力可能な文字数を超えています。11文字以下で入力して下さい。',
    },
    {
      message: '${label}は半角数字で入力してください。',
      pattern: REGEX.TEL,
    },
  ],
  postCd: [
    () => ({
      validator(_, value) {
        if (value && value.length !== 7) {
          return Promise.reject(new Error('7文字で入力して下さい。'));
        }
        return Promise.resolve();
      },
    }),
    {
      message: '${label}は半角数字で入力してください。',
      pattern: REGEX.NUMBER,
    },
  ],
  address1: [
    {
      required: true,
      message: '住所1は必須入力です。',
    },
    {
      max: 256,
      message: '入力可能な文字数を超えています。256文字以下で入力して下さい。',
    },
  ],
  address2: [
    {
      max: 256,
      message: '入力可能な文字数を超えています。256文字以下で入力して下さい。',
    },
  ],
  address3: [
    {
      max: 256,
      message: '入力可能な文字数を超えています。256文字以下で入力して下さい。',
    },
  ],
  pointInfomation: [
    {
      max: 256,
      message: '入力可能な文字数を超えています。256文字以下で入力して下さい。',
    },
  ],
};

export const rulesUser = {
  mailAddress: [
    {
      required: true,
    },
    {
      type: 'email',
      message: 'メールアドレスの入力が正しくありません。',
    },
    { max: 256 },
  ],
  userId: [
    {
      required: true,
    },
    {
      message: '利用できない文字が含まれています。',
      pattern: REGEX.TEXT_WITHOUT_JAPANESE,
    },
    { max: 256 },
    { min: 6 },
  ],
  userNm: [
    {
      required: true,
    },
    { max: 128 },
  ],
  authRoleDiv: [
    {
      required: true,
    },
  ],
  roleDiv: [
    {
      required: true,
    },
  ],
  customerId(isRequired) {
    return [
      {
        required: isRequired,
      },
    ];
  },
  companyId(isRequired) {
    return [
      {
        required: isRequired,
      },
    ];
  },
  driver(isRequired) {
    return [
      {
        required: isRequired,
      },
    ];
  },
};

export const rulesCar = {
  companyId: [{ required: true }],
  owner: [{ max: 128 }],
  noPlate: [{ max: 50 }],
  cartypeId: [{ required: true }],
  carManagementNum: [{ max: 50 }],
  chassisNumber: [{ max: 20 }],
  leaseFee: [
    () => ({
      validator(_, value) {
        if (value && value.length > 9) {
          return Promise.reject(
            new Error(
              '入力可能な文字数を超えています。9文字以下で入力して下さい。',
            ),
          );
        }
        return Promise.resolve();
      },
    }),
    {
      pattern: REGEX.NUMBER,
      message: '${label}は半角数字で入力してください。',
    },
  ],
};

export const rulesSlot = {
  companyId: [
    {
      required: true,
    },
  ],
  mServiceId: [
    {
      required: true,
    },
  ],
  startTime: [
    {
      required: true,
    },
  ],
  endTime: [
    {
      required: true,
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        const startTime = getFieldValue('startTime');
        if (value && startTime && value.isBefore(startTime)) {
          return Promise.reject(
            new Error('終了時間は開始時間より後の時間を入力してください。'),
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  cartypeId: [
    {
      required: true,
    },
  ],
};
export const rulesConpany = {
  companyNm: [
    {
      required: true,
    },
    { max: 128 },
  ],
  companyKnNm: [
    {
      required: true,
    },
    { max: 128 },
    {
      pattern: REGEX.HIRAGANA,
      message: '${label}はひらがなで入力してください。',
    },
  ],
  address: [{ max: 768 }],
  telNumber: [
    {
      max: 11,
      message: ' 入力可能な文字数を超えています。11文字以下で入力して下さい。',
    },
    {
      message: '${label}は半角数字で入力してください。',
      pattern: REGEX.TEL,
    },
  ],
  paymentClosingMonthDiv: [
    {
      required: true,
    },
  ],
  paymentDeadlineDiv: [
    {
      required: true,
    },
  ],
  deadlineMonthDiv: [
    {
      required: true,
    },
  ],
  deadlineDayDiv: [
    {
      required: true,
    },
  ],
  fare: [
    () => ({
      validator(_, value) {
        if (value && value.length > 9) {
          return Promise.reject(
            new Error(
              '入力可能な文字数を超えています。9文字以下で入力して下さい。',
            ),
          );
        }
        return Promise.resolve();
      },
    }),
    {
      pattern: REGEX.NUMBER,
      message: '支払単価は半角数字で入力してください。',
    },
  ],
};

export const rulesDriver = {
  driverNm: [
    {
      required: true,
    },
    {
      max: 128,
    },
  ],
  driverNmKn: [
    {
      required: true,
    },
    {
      max: 128,
    },
    {
      pattern: REGEX.HIRAGANA,
      message: '${label}はひらがなで入力してください。',
    },
  ],
  telNumber: [
    {
      max: 11,
      message: ' 入力可能な文字数を超えています。11文字以下で入力して下さい。',
    },
    {
      message: '${label}は半角数字で入力してください。',
      pattern: REGEX.TEL,
    },
  ],
  companyId: [
    {
      required: true,
    },
  ],
  restEndTime: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        const startTime = getFieldValue('restStartTime');
        if (value && startTime && value.isBefore(startTime)) {
          return Promise.reject(
            new Error('終了時間は開始時間より後の時間を入力してください。'),
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  maxWorkTime: [
    {
      pattern: REGEX.HOUR_AMOUNT,
      message: '最大勤務時間は半角数字で入力してください。',
    },
  ],
};
export const rulesCreatePayment = {
  companyId: [{ required: true }],
  targetMonth: [{ required: true }],
};
export const rulesOther = {
  require: { required: true },
  postCd: [
    () => ({
      validator(_, value) {
        if (value && value.length !== 7) {
          return Promise.reject(new Error('7文字で入力して下さい。'));
        }
        return Promise.resolve();
      },
    }),
    {
      message: '郵便番号は半角数字で入力してください。',
      pattern: REGEX.NUMBER,
    },
  ],
  fileName: [
    {
      validator: (_, files) => {
        const check = files?.some((file) => file?.name?.length > 127);
        if (check) {
          return Promise.reject(
            new Error('ファイル名は128文字以下にしてください。'),
          );
        }
        return Promise.resolve();
      },
    },
  ],
  invoiceMoney: [
    {
      max: 9,
    },
    {
      pattern: REGEX.NUMBER,
      message: '請求単価は半角数字で入力してください。',
    },
  ],
  telNumber: [
    {
      max: 11,
      message: '入力可能な文字数を超えています。11文字以下で入力して下さい。',
    },
    {
      pattern: REGEX.TEL,
      message: '${label}は半角数字で入力してください。',
    },
  ],
  knNm: [
    {
      required: true,
    },
    { max: 128 },
    {
      pattern: REGEX.HIRAGANA,
      message: '${label}はひらがなで入力してください。',
    },
  ],
};
export const rulesAttenDance = {
  workdate: [
    {
      required: true,
    },
  ],
  userNm: [
    {
      required: true,
    },
  ],
  leavingDatetime: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        const goingDatetime = getFieldValue('goingDatetime');
        if (value && goingDatetime && value.isBefore(goingDatetime)) {
          return Promise.reject(
            new Error('退勤は出勤より後の時間を入力してください。'),
          );
        }
        return Promise.resolve();
      },
    }),
  ],
  goingDatetime: [
    {
      required: true,
    },
  ],
  restEndDt: (index) => {
    return [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const restStartDt =
            getFieldValue('attendanceRests')?.[index]?.restStartDt;
          if (value && restStartDt && value.isBefore(restStartDt)) {
            return Promise.reject(
              new Error('休憩終了は休憩開始より後の時間を入力してください。'),
            );
          }
          return Promise.resolve();
        },
      }),
    ];
  },
};

export const orderBarcode = {
  slipNo: [{ required: true }, { max: 20 }],
  customerId: [{ required: true }],
  workDate: [{ required: true }],
  unloadStatus: [{ required: true }],
};
