export const getLabelByValue = (value, array) =>
  array?.find((e) => e?.value === value)?.label || '';

export const arrayDiff = (arr1, arr2) => {
  const unique1 = arr1.filter((o) => arr2.indexOf(o) === -1);
  const unique2 = arr2.filter((o) => arr1.indexOf(o) === -1);
  return unique1.concat(unique2);
};

export const range = (a, b, step) => {
  let A = [];
  if (typeof a === 'number') {
    A[0] = a;
    step = step || 1;
    while (a + step <= b) {
      a += step;
      A[A.length] = a;
    }
  } else {
    let s = 'abcdefghijklmnopqrstuvwxyz';
    if (a === a.toUpperCase()) {
      b = b.toUpperCase();
      s = s.toUpperCase();
    }
    s = s.substring(s.indexOf(a), s.indexOf(b) + 1);
    A = s.split('');
  }
  return A;
};

export const getMinItemByKey = (arr, key) => {
  if (Array.isArray(arr) && arr.length > 0) {
    return arr.reduce((prev, curr) => (prev[key] < curr[key] ? prev : curr));
  }
  return null;
};

export const getMaxItemByKey = (arr, key) => {
  if (Array.isArray(arr) && arr.length > 0) {
    return arr.reduce((prev, curr) => (prev[key] > curr[key] ? prev : curr));
  }
  return null;
};
