import React from 'react';
import { IconDatePicker } from '@assets/icons';
import { formatDate } from '@common/utils/dateHelper';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

function MyDatePicker({
  typePicker = 'date',
  disabledBeforeDate = false,
  disabledDateFunc,
  ...props
}) {
  const handleDisabledBeforeDate = (current) => {
    return disabledBeforeDate && dayjs().add(-1, 'days') >= current;
  };
  return (
    <DatePicker
      format={(value) => formatDate(value, typePicker)}
      showToday={false}
      picker={typePicker}
      suffixIcon={<IconDatePicker className="anticon" />}
      disabledDate={disabledDateFunc || handleDisabledBeforeDate}
      {...props}
    />
  );
}

export default MyDatePicker;

MyDatePicker.propTypes = {
  typePicker: PropTypes.string,
  disabledBeforeDate: PropTypes.bool,
  disabledDateFunc: PropTypes.func,
};
