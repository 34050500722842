import React, { useEffect, useState } from 'react';
import { usePostResetPwd } from '@services/hooks/auth';
import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import PropTypes from 'prop-types';

function FormResetPassword({ handleResetPwd, handleBackLogin }) {
  const [isError, setIsError] = useState(false);
  const [form] = useForm();
  const { mutate, error } = usePostResetPwd();
  const handleFormSubmit = (data) => {
    mutate(data, {
      onSuccess: () => {
        handleResetPwd();
      },
    });
  };

  const formChange = () => {
    setIsError(false);
  };

  useEffect(() => {
    if (error) setIsError(true);
  }, [error]);

  return (
    <div>
      <div className="fz-24 fw-700 mb-20">パスワード再発行</div>
      <div className="fz-14">
        メールアドレスを入力のうえ、送信ボタンを押してください。
      </div>
      <div className="fz-14">
        パスワード再発行用URLを記載したメールをお送りします。
      </div>
      <Form
        onValuesChange={formChange}
        form={form}
        className="reset-pwd-form"
        onFinish={handleFormSubmit}
      >
        {isError && (
          <Typography.Title
            level={5}
            type="danger"
            style={{ textAlign: 'left' }}
          >
            このメールアドレスのアカウントは存在しません。
          </Typography.Title>
        )}
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'メールアドレスは必須入力です。' },
            {
              type: 'email',
              message: 'メールアドレスの入力が正しくありません。',
            },
          ]}
          className="mb-30"
        >
          <Input
            status={isError && 'error'}
            placeholder="メールアドレスを入力してください"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" className="w-full mb-20">
          送信
        </Button>
        <Button type="primary" onClick={handleBackLogin} className="w-full">
          ログイン画面に戻る
        </Button>
      </Form>
    </div>
  );
}

export default FormResetPassword;

FormResetPassword.propTypes = {
  handleResetPwd: PropTypes.func,
  handleBackLogin: PropTypes.func,
};
