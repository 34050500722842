import React, { useEffect, useState } from 'react';
import { IconArrowCollapse, IconPlus } from '@assets/icons';
import { rulesOther } from '@common/constant/rules';
import { timeDateDisplay } from '@common/utils/dateHelper';
import { Button, Form, Row, Tooltip, Upload } from 'antd';
import PropTypes from 'prop-types';

function FieldUploadFileMaster({ name, ...props }) {
  const { accept, fieldIdFile } = { ...props };
  const [isHidden, setIsHidden] = useState(true);
  const [maxCount, setMaxCount] = useState();
  const form = Form.useFormInstance();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const beforeUpload = (file) => {
    if (file.size > 1024 * 1024 * 5) {
      return Upload.LIST_IGNORE;
    }
    return false;
  };
  const item = (file, action) => {
    const handleRemoveFile = (fileRemove, actionHandle) => {
      const key = fieldIdFile || `${name.slice(0, -1)}Id`;
      const tempDelete = { deleteFlg: '1' };
      if (fileRemove[key]) {
        tempDelete[key] = fileRemove[key];
        // add flag delete
        form.getFieldValue(name).push(tempDelete);
      }
      actionHandle.remove();
    };
    return (
      <Row className="mb-10" justify="space-between" align="middle">
        <a
          href={file.url}
          target="_blank"
          className="fw-400 fz-16 color-primary pointer w-240"
          rel="noreferrer"
          title={file.url}
        >
          <Tooltip
            className="text-truncate w-full dis-flex-inline"
            title={file.name ? file.name : file.fileNm}
          >
            {file.name ? file.name : file.fileNm}
          </Tooltip>
        </a>
        <Row className="mr-20">
          <span className="fw-400 fz-16 color-bgray mr-50">
            {timeDateDisplay(file.updDatetime)}
          </span>
          <span
            onClick={() => handleRemoveFile(file, action)}
            className="fw-400 fz-16 color-red pointer"
          >
            削除
          </span>
        </Row>
      </Row>
    );
  };
  const renderItem = (_, file, fileList, action) => {
    const fieldNameIdFile = fieldIdFile || `${name.slice(0, -1)}Id`;
    const idName = file[fieldNameIdFile] ? fieldNameIdFile : 'uid';
    const fileListFilter = fileList.filter((fileItem) => !fileItem.deleteFlg);
    const key = fileListFilter.findIndex(
      (fileItem) => fileItem[idName] === file[idName],
    );
    return (
      !file.deleteFlg && (
        <div>
          {isHidden
            ? fileListFilter.length - key < 4 && item(file, action)
            : item(file, action)}
          {fileListFilter[0]?.uid === file?.uid && (
            <span
              onClick={() => {
                setIsHidden((previous) => !previous);
              }}
              className="flex-justify-end fw-400 fz-16 color-bgray mr-20 mb-20 pointer"
            >
              {fileListFilter.length > 3 &&
                (isHidden ? (
                  <span className="flex-center">
                    すべて表示{' '}
                    <IconArrowCollapse className="rotate-180 ml-10" />
                  </span>
                ) : (
                  <span className="flex-center">
                    表示を隠す
                    <IconArrowCollapse className="ml-10" />
                  </span>
                ))}
            </span>
          )}
        </div>
      )
    );
  };
  useEffect(() => {
    setMaxCount(
      form.getFieldValue(name) ? form.getFieldValue(name).length + 5 : 5,
    );
  }, []);

  return (
    <Form.Item
      getValueFromEvent={normFile}
      valuePropName="fileList"
      name={name}
      rules={rulesOther.fileName}
    >
      <Upload
        className="w-full dis-flex flex-col-reverse"
        accept={accept}
        itemRender={renderItem}
        beforeUpload={beforeUpload}
        maxCount={maxCount}
        multiple
      >
        <Button className="w-full h-48 fz-20 fw-700" type="dashed">
          <Row justify="center" align="middle">
            <IconPlus className="fill-primary mr-15" />
            ファイルを追加
          </Row>
        </Button>
      </Upload>
    </Form.Item>
  );
}

export default FieldUploadFileMaster;

FieldUploadFileMaster.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
