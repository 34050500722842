const DIV_NAME = {
  ROLES: 'roles',
  INVOICE_DEADLINE: 'invoice_deadline',
  PAYMENT_DEADLINE: 'payment_deadline',
  WORK_FORM: 'work_form',
  QUANTITY_UNIT: 'quantity_unit',
  PACKING: 'packing',
  ORDER_WAY: 'order_way',
  TIME: 'time',
  DOW: 'dow',
  CONSOLIDATION: 'consolidation',
  STATUS: 'status',
  SERVICE_STATUS: 'service_status',
  SERVICE_YMD: 'service_kbn',
  OPERATING_DAY_SEARCH_CATEGORY: 'operating_day_search_category',
  WORK_KINDS: 'work_kinds',
  ALLOCATION_STATUS: 'allocation_status',
  FORM: 'form',
  FORM_OUTPUT: 'form_output',
  INVOICE_CLOSING_MONTH: 'invoice_closing_month',
  ORDER_LINKAGE: 'order_linkage',
  WORK_STATUS: 'work_status',
  BASE_TYPE: 'base_type',
  PICK_DELI_TYPE: 'pick_deli_type',
  PICK_DELI_WAY: 'pick_deli_way',
  DEADLINE_MONTH: 'deadline_month',
  DEADLINE_DAY: 'deadline_day',
  AUTH_ROLE: 'auth_role',
  COLLECT_MONEY: 'collect_money',
  CALC_WAY_DIV: 'calc_way_div',
  BARCODE_STATUS: 'barcode_status',
};

export const WEEK_DAY_DIV = [
  { label: '月', value: 'dowFlgMo' },
  { label: '火', value: 'dowFlgTu' },
  { label: '水', value: 'dowFlgWe' },
  { label: '木', value: 'dowFlgTh' },
  { label: '金', value: 'dowFlgFr' },
  { label: '土', value: 'dowFlgSa' },
  { label: '日', value: 'dowFlgSu' },
];

export const BOOL_DIV = [
  { label: 'しない', value: '0' },
  { label: 'する', value: '1' },
];

export const EXCLUDE_BOOL_DIV = [
  { label: 'しない', value: '1' },
  { label: 'する', value: '0' },
];

export const LADING_DOW_DIV = [
  { label: '平日(祝日を含む)', value: '01' },
  { label: '休日', value: '02' },
  { label: '毎日', value: '03' },
  { label: '曜日指定', value: '04' },
];

export const COLLECT_MONEY_FLG = [
  {
    label: '要集金',
    value: '1',
  },
  {
    label: '集金不要',
    value: '0',
  },
];

export const ROUTE_FLG = [
  {
    label: '可能',
    value: '1',
  },
  {
    label: '不可能',
    value: '0',
  },
];

export const BARCODE_READER_FLG = [
  {
    label: '通常盤',
    value: '0',
  },
  {
    label: '高速版',
    value: '1',
  },
];

export const PACKING_VALUE_OTHER = '99';

export default DIV_NAME;

export const TYPE_EDIT_ROUTE = [
  {
    label: '時間と配送順を再計算',
    value: true,
  },
  { label: '時間のみ再計算', value: false },
];

export const ORDER_WAY_LINKAGE = {
  ASKUL: '04',
  SEINO: '06',
  FUKUYAMA: '07',
};
