import { useMutation, useQuery } from 'react-query';
import userApi from '@services/apis/users';

const useGetUsers = (params) =>
  useQuery(['get-users', params], () => userApi.getListUsers(params), {
    staleTime: 5000,
    keepPreviousData: true,
  });

export default useGetUsers;

export const usePostUser = () =>
  useMutation((params) => userApi.postUser(params));

export const useUpdateUser = () =>
  useMutation(({ params, id }) => userApi.updateUser(params, id));
export const useGetUserDetail = (id) =>
  useQuery(['get_user_detail', id], () => userApi.getUserDetail(id), {
    staleTime: Infinity,
    enabled: !!id,
    cacheTime: 0,
  });
