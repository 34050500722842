import React from 'react';
import { IconPrev } from '@assets/icons';
import { formatDateFilter } from '@common/utils/dateHelper';
import {
  usePostAttenDance,
  useUpdateAttenDance,
} from '@services/hooks/attenDance';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import FormAttendancesCreate from './FormAttendancesCreate';

function ModalAttendance({
  isOpenCreateModal,
  handleCloseCreateModal,
  refetch,
  setAlert,
  form,
  dataCompanyList,
  isEdit,
  initialvalue,
  dataDetailUserId,
}) {
  const { mutate, isLoading } = usePostAttenDance();
  const { mutate: updateAttendances } = useUpdateAttenDance();

  const handleSubmitEditForm = (values) => {
    const dataPost = {
      ...values,
      workDate: formatDateFilter(values?.workDate),
    };

    updateAttendances(
      { params: dataPost, id: initialvalue?.attendanceId },
      {
        onSuccess: async () => {
          handleCloseCreateModal();
          await refetch();
          setAlert({ msg: '勤怠実績を登録しました。', type: 'success' });
        },
        onError: () => {
          handleCloseCreateModal();
          setAlert({
            msg: '予期せぬエラーが発生しました。。',
            type: 'error',
          });
        },
      },
    );
  };
  const handleSubmitForm = (values) => {
    const specifiedValues = dataDetailUserId && {
      companyId: dataDetailUserId?.companyId,
      mUserId: dataDetailUserId?.mUserId,
    };
    const dataPost = {
      ...values,
      ...specifiedValues,
      workDate: formatDateFilter(values?.workDate),
    };

    mutate(dataPost, {
      onSuccess: async () => {
        handleCloseCreateModal();
        await refetch();
        setAlert({ msg: '勤怠実績を登録しました。', type: 'success' });
      },
      onError: () => {
        handleCloseCreateModal();
        setAlert({
          msg: '予期せぬエラーが発生しました。。',
          type: 'error',
        });
      },
    });
  };

  const triggerSubmitForm = () => {
    form.submit();
  };
  return (
    <Modal
      className="modal-create-user"
      width={880}
      open={isOpenCreateModal}
      onCancel={() => {
        handleCloseCreateModal();
      }}
      onOk={triggerSubmitForm}
      cancelButtonProps={{ type: 'text', size: 'small' }}
      okButtonProps={{ size: 'small', disabled: isLoading }}
      cancelText={
        <div className="back-btn">
          <IconPrev />
          キャンセル
        </div>
      }
      okText={<div className="w-138">保存</div>}
      destroyOnClose
    >
      <h2 className="fw-600">{isEdit ? '打刻修正' : '打刻登録'}</h2>
      <FormAttendancesCreate
        form={form}
        handleSubmitForm={isEdit ? handleSubmitEditForm : handleSubmitForm}
        dataCompanyList={dataCompanyList}
        initialValues={initialvalue}
        attendanceId={initialvalue?.attendanceId}
        mUserId={initialvalue?.mUserId}
        isEdit={isEdit}
        dataDetailUserId={dataDetailUserId}
      />
    </Modal>
  );
}

export default ModalAttendance;

ModalAttendance.propTypes = {
  isOpenCreateModal: PropTypes.bool,
  handleCloseCreateModal: PropTypes.func,
  refetch: PropTypes.func,
  setAlert: PropTypes.func,
  form: PropTypes.object,
  dataCompanyList: PropTypes.array,
  isEdit: PropTypes.bool,
  initialvalue: PropTypes.object,
  dataDetailUserId: PropTypes.object,
};
