import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import auth from './slices/authSlice';
import layout from './slices/layoutSlice';

const reducers = combineReducers({
  auth,
  layout,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [''], // only auth will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});
