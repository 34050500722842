export const DATE_FORMAT_DISPLAY = 'YYYY年MM月DD日(ddd)';
export const MONTH_FORMAT_DISPLAY = 'MM月';
export const DATE_FORMAT_NORMAL = 'YYYY/MM/DD';
export const DATE_FORMAT_WITHOUT_YEAR = 'DD/MM';
export const DATE_FORMAT_DEFAULT = 'MM/DD/YYYY';
export const DATE_FORMAT_API = 'YYYY-MM-DD';
export const TIME_FORMAT_API = 'HH:mm:ss';
export const DATE_TIME_FORMAT_API = 'YYYYMMDD HHmm';
export const TIME_FORMAT_NORMAL = 'HH:mm';
export const TIME_DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const YEAR_MONTH_FORMAT_API = 'YYYYMM';
export const YEAR_MONTH_FORMAT_API_2 = 'YYYY-MM';
export const YEAR_MONTH_FORMAT_DISPLAY = 'YYYY年MM月';
