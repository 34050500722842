import React, { createContext, useContext, useState } from 'react';

const AlertContext = createContext();

export const useAlert = () => {
  const { alert, setAlert } = useContext(AlertContext);
  return { alert, setAlert };
};

// eslint-disable-next-line react/prop-types
function AlertProvider({ children }) {
  const [alert, setAlert] = useState({
    msg: '',
    type: 'success',
  });

  const value = React.useMemo(() => {
    return { alert, setAlert };
  }, [alert]);

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
}

export default AlertProvider;
